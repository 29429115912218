<template>
    <div class="customer">
        <div
            class="statistics_box"
            v-if="
                !btnP.depSearch && (btnP.tmMail || btnP.aliMail || btnP.wyMail)
            "
        >
            <div class="tm" v-if="btnP.tmMail">
                <p class="title">
                    <span class="iconfont icon-tengxunqiyeyouxiang"></span>
                    腾讯企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            tmAccount.maxReportNumber
                                ? tmAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>部门可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.consumedNumber
                                ? tmAccount.consumedNumber +
                                  tmAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>员工已申请报备数</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.departmentAssignedNumber
                                ? tmAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>已分配员工名额</span>
                    </p>
                    <p>
                        <span>{{
                            tmAccount.maxReportNumber -
                            tmAccount.departmentAssignedNumber
                                ? tmAccount.maxReportNumber -
                                  tmAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>可分配员工名额</span>
                    </p>
                </div>
            </div>
            <div class="ali" v-if="btnP.aliMail" style="margin: 0 10px">
                <p class="title">
                    <span class="iconfont icon-alimailaliyouxiang"></span
                    >阿里企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            aliAccount.maxReportNumber
                                ? aliAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>部门可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.consumedNumber
                                ? aliAccount.consumedNumber +
                                  aliAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>员工已申请报备数</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.departmentAssignedNumber
                                ? aliAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>已分配员工名额</span>
                    </p>
                    <p>
                        <span>{{
                            aliAccount.maxReportNumber -
                            aliAccount.departmentAssignedNumber
                                ? aliAccount.maxReportNumber -
                                  aliAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>可分配员工名额</span>
                    </p>
                </div>
            </div>
            <div class="ali" v-if="btnP.wyMail" style="margin: 0 10px">
                <p class="title">
                    <span class="iconfont icon-alimailaliyouxiang"></span
                    >网易企业邮箱
                </p>
                <div class="content_box">
                    <p>
                        <span>{{
                            wyAccount.maxReportNumber
                                ? wyAccount.maxReportNumber
                                : 0
                        }}</span>
                        <span>部门可最大报备数</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.consumedNumber
                                ? wyAccount.consumedNumber +
                                  wyAccount.channelReportNumber
                                : 0
                        }}</span>
                        <span>员工已申请报备数</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.departmentAssignedNumber
                                ? wyAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>已分配员工名额</span>
                    </p>
                    <p>
                        <span>{{
                            wyAccount.maxReportNumber -
                            wyAccount.departmentAssignedNumber
                                ? wyAccount.maxReportNumber -
                                  wyAccount.departmentAssignedNumber
                                : 0
                        }}</span>
                        <span>可分配员工名额</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="search-box">
            <div class="search-one">
                <p>
                    <Department
                        ref="department"
                        :departmentWidth="100"
                        :adminWidth="100"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                </p>
                <!-- <p v-if="btnP.depSearch">
                    <span>部门</span>
                    <el-select
                        v-model="dempVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId()"
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p v-if="btnP.userSearch">
                    <span>成员</span>
                    <el-select
                        v-model="userVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p> -->
                <span v-if="btnP.tmMail || btnP.aliMail || btnP.wyMail"
                    >品牌分类</span
                >
                <el-radio-group
                    size="small"
                    v-model="returnStatusVal"
                    @change="(currentPage = 1), (pagesize = 20)"
                >
                    <el-radio-button
                        label="腾讯"
                        v-if="btnP.tmMail"
                    ></el-radio-button>
                    <el-radio-button
                        label="阿里"
                        v-if="btnP.aliMail"
                    ></el-radio-button>
                    <el-radio-button
                        label="网易"
                        v-if="btnP.wyMail"
                    ></el-radio-button>
                </el-radio-group>
                <span>查询</span>
                <el-input
                    size="small"
                    style="width: 215px"
                    placeholder="请输入员工名称"
                    v-model.trim="nameVal"
                    clearable
                >
                </el-input>
                <el-button
                    @click="(pagesize = 20), (currentPage = 1), getData()"
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    style="border-color: #2370eb"
                ></el-button>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">员工列表</div>
            <span></span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column
                label="员工名称"
                prop="nickName"
                show-overflow-tooltip
                width="80"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属部门"
                prop="departmentName"
                show-overflow-tooltip
                width="80"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="员工当前可最大报备数"
                show-overflow-tooltip
                width="192"
            >
                <template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.maxReportNumberChanceTencent
                                : scope.row.maxReportNumberTencent
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{ scope.row.maxReportNumberAli }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{ scope.row.maxReportNumberNetease }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="已申请报备数"
                width="120"
                show-overflow-tooltip
                prop=""
            >
                <template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.consumedNumberChanceTencent
                                : scope.row.consumedNumberTencent +
                                  scope.row.channelReportNumberTencent
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{
                            scope.row.consumedNumberAli +
                            scope.row.channelReportNumberAli
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{
                            scope.row.consumedNumberNetease +
                            scope.row.channelReportNumberNetease
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="剩余可报备数"
                width="120"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '腾讯'"
                        >{{
                            btnP.tmReport
                                ? scope.row.maxReportNumberChanceTencent -
                                  scope.row.consumedNumberChanceTencent
                                : scope.row.maxReportNumberTencent -
                                  (scope.row.consumedNumberTencent +
                                      scope.row.channelReportNumberTencent)
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '阿里'"
                        >{{
                            scope.row.maxReportNumberAli -
                            (scope.row.consumedNumberAli +
                                scope.row.channelReportNumberAli)
                        }}</span
                    >
                    <span
                        style="display: inline-block; width: 50px"
                        v-if="returnStatusVal == '网易'"
                        >{{
                            scope.row.maxReportNumberNetease -
                            (scope.row.consumedNumberNetease +
                                scope.row.channelReportNumberNetease)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column width="80" label="操作">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.adjust"
                        type="text"
                        style="color: #2370eb; margin-right: 15px"
                        @click="onUpData(scope.row)"
                        >编辑</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 编辑 -->
        <el-dialog
            :visible.sync="dialogAdjust"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    调整名额
                </div>
            </template>
            <div>
                <UpData ref="upData" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { adminList } from '@/api/userquota/userquota';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import { accountBrief } from '@/api/report/report';
import { accountAdmin } from '@/api/depquota/depquota';
import { accountAdmin as newAccountAdmin } from '@/api/report/newReport';
import UpData from './upData.vue';
import Department from '../../../components/Department';
export default {
    components: {
        UpData,
        Department,
    },
    data() {
        return {
            nameVal: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            btnP: {},
            dempData: [],
            userData: [],
            dempVal: '',
            userVal: '',
            dialogAdjust: false,
            tmAccount: {},
            aliAccount: {},
            wyAccount: {},
            returnStatusVal: '腾讯',
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDempData();
            }
            if (btn.tmMail && !btn.aliMail && !btn.wyMail) {
                this.returnStatusVal = '腾讯';
            }
            if (btn.aliMail && !btn.tmMail && !btn.wyMail) {
                this.returnStatusVal = '阿里';
            }
            if (btn.wyMail && !btn.tmMail && !btn.aliMail) {
                this.returnStatusVal = '网易';
            }
            if (!btn.tmMail && btn.aliMail && btn.wyMail) {
                his.returnStatusVal = '阿里';
            }
            if (!btn.depSearch && btn.userSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (!btn.depSearch) {
                this.getAccountBrief();
            }
            this.getData();
        },
        getAccountBrief() {
            let data = {
                param: {
                    productType: 1,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            if (this.btnP.tmReport) {
                data.param.productType = 5;
                newAccountAdmin(data).then((res) => {
                    this.tmAccount = res.data;
                });
            } else {
                accountAdmin(data).then((res) => {
                    this.tmAccount = res.data;
                });
            }

            let obj = {
                param: {
                    productType: 2,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            accountAdmin(obj).then((res) => {
                this.aliAccount = res.data;
            });
            let obj1 = {
                param: {
                    productType: 4,
                    departmentId: sessionStorage.getItem('departmentId'),
                },
            };
            accountAdmin(obj1).then((res) => {
                this.wyAccount = res.data;
            });
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },

        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.dempVal = data.departmentId;
            this.userVal = data.adminId;
            this.getData();
        },
        getData() {
            let data = {
                param: {
                    name: this.nameVal,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch && this.btnP.userSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                if (this.userVal) {
                    data.param.adminId = this.userVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.departmentId = this.dempVal;
            }
            console.log(this.btnP.depSearch && this.btnP.userSearch);
            if (this.btnP.depSearch && this.btnP.userSearch) {
                data.param.departmentId = this.dempVal;
                data.param.adminId = this.userVal;
            }
            if (!this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }

            if (this.nameVal) {
                data.param.name = this.nameVal;
            }
            adminList(data).then((res) => {
                console.log(res);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onUpData(row) {
            this.dialogAdjust = true;
            let i = 1;
            if (this.returnStatusVal == '腾讯') {
                if (this.btnP.tmReport) {
                    i = 5;
                } else {
                    i = 1;
                }
            } else if (this.returnStatusVal == '阿里') {
                i = 2;
            } else if (this.returnStatusVal == '网易') {
                i = 4;
            }
            setTimeout(() => {
                this.$refs.upData.getData(row, i);
            });
        },
        handleClose() {
            this.dialogAdjust = false;
            this.getData();
            this.getAccountBrief();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.statistics_box {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    height: 120px;
    .tm {
        flex: 1;
        background: #fff;
        margin-right: 12px;
        border-radius: 10px;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        .title span {
            color: #2370eb;
            background: #e9f2ff;
        }
        .content_box {
            background: #f7f9fd;
            p:last-child {
                span:nth-child(1) {
                    font-size: 18px;
                    color: #333333;
                    font-weight: 600;
                    color: #2370eb;
                }
            }
        }
    }
    .ali {
        flex: 1;
        background: #fff;
        border-radius: 10px;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        .title span {
            background: #fff0e9;
            color: #ff562d;
        }
        .content_box {
            background: #fef6f3;
            p:last-child {
                span:nth-child(1) {
                    font-size: 18px;
                    color: #333333;
                    font-weight: 600;
                    color: #ff562d;
                }
            }
        }
    }
    .title {
        font-size: 14px;
        font-weight: 600;
        padding: 12px 0;
        span {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 28px;
            text-align: center;
            line-height: 28px;
            margin-right: 16px;
            font-weight: 500;
        }
    }
    .content_box {
        flex: 1;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 5px;
        p {
            span {
                display: block;
            }
            span:nth-child(1) {
                font-size: 18px;
                color: #333333;
                font-weight: 600;
            }
            span:nth-child(2) {
                font-size: 14px;
                color: #333333;
                margin-top: 7px;
            }
        }
    }
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            align-items: center;

            button {
                background: #2370eb;
                border-radius: 2px;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .status_btn {
            font-weight: 600;
            font-size: 14px;
            color: #333333;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
    }
}
</style>
